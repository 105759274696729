import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Header from "./components/Header";
import Home from "./components/Home";
import ThumbsSlider from "./components/carta/ThumbsSlider";
import CartaBasica from "./components/carta/CartaBasica";
import Preloader from "./components/Preloader";
import useDocumentTitle from "./hooks/useDocumentTitle";
import { fetchRestaurantData, fetchMenuAndCartaData } from "./api/queries";
import "./App.css";

function App() {
  const [isCartaBasicaOpen, setIsCartaBasicaOpen] = useState(false);
  const restaurant_id = process.env.REACT_APP_RESTAURANT_ID;
  const queryClient = useQueryClient();

  // Query principal para los datos del restaurante
  const {
    data: menuData,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["restaurant", restaurant_id],
    queryFn: () => fetchRestaurantData(restaurant_id),
  });

  // Prefetch en background de CartaBasica
  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["menuAndCarta", restaurant_id],
      queryFn: () => fetchMenuAndCartaData(restaurant_id),
    });
  }, [queryClient, restaurant_id]);

  const toggleCartaBasica = () => {
    setIsCartaBasicaOpen(!isCartaBasicaOpen);
  };

  useDocumentTitle(
    menuData ? `${menuData.restaurant.name}` : "Cargando..."
  );

  if (isLoading) {
    return <Preloader message="Cargando datos..." />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const filteredMenu = menuData.carta
    .map((menuItem) => ({
      ...menuItem,
      dishes: menuItem.dishes.filter((dish) => dish["tiene-video"]),
    }))
    .filter((menuItem) => menuItem.dishes.length > 0);

  return (
    <Router>
      <div className="App">
        <Header
          restaurant={menuData.restaurant}
          onCartaClick={toggleCartaBasica}
          isCartaOpen={isCartaBasicaOpen}
        />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                menu={filteredMenu}
                whatsappNumber={menuData.restaurant.whatsapp}
                email={menuData.restaurant.email}
              />
            }
          />
        </Routes>
        {!isCartaBasicaOpen && <ThumbsSlider />}
        {isCartaBasicaOpen && (
          <CartaBasica
            restaurant={menuData.restaurant}
            restaurant_id={restaurant_id}
            onClose={toggleCartaBasica}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
