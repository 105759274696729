// src/utils/getIcon.js

import { ReactComponent as LogoLight } from "../icons/clientes/logo-light.svg"; // Ajustado path
import { ReactComponent as LogoDark } from "../icons/clientes/logo-dark.svg"; // Ajustado path
import { ReactComponent as IconBanquete } from "../icons/secciones/icon-banquete.svg";
import { ReactComponent as IconBarra } from "../icons/secciones/icon-barra.svg";
import { ReactComponent as IconCoctel } from "../icons/secciones/icon-coctel.svg";
import { ReactComponent as IconEstaciones } from "../icons/secciones/icon-estaciones.svg";
import { ReactComponent as IconWines } from "../icons/secciones/icon-wines.svg";
import { ReactComponent as IconDesayunos } from "../icons/secciones/icon-desayunos.svg";
import { ReactComponent as IconOpiniones } from "../icons/secciones/icon-opiniones.svg";




const iconMapping = {
  // Logos - mapeados exactamente como vienen en el payload
  "logo-light": LogoLight,
  "logo-dark": LogoDark,

  // Iconos de tipos de platos
  "icon-banquete": IconBanquete,
  "icon-barra": IconBarra,
  "icon-coctel": IconCoctel,
  "icon-estaciones": IconEstaciones,
  "icon-wines": IconWines,
  "icon-desayunos": IconDesayunos,
  "icon-opiniones": IconOpiniones,
  


};

const getIcon = (iconName) => {
  return iconMapping[iconName] || null;
};

export default getIcon;
